const condAttr1 = [
  {
    value: 1,
    label:'等于'
  },{
    value: 2,
    label:'小于'
  },{
    value: 3,
    label:'小于等于'
  },{
    value: 4,
    label:'大于'
  },{
    value: 5,
    label:'大于等于'
  },{
    value: 6,
    label:'不等于'
  },
];
const condAttr2 = [
  {
    value: 1,
    label:'等于'
  },{
    value: 2,
    label:'不等于'
  },{
    value: 3,
    label:'包含'
  },{
    value: 4,
    label:'不包含'
  }
];
const unitArr = {
  1: {
    name: '用户年龄',
    unit: '岁'
  },
  2: {
    name: '人脸相似度',
    unit: '分'
  },
}

;

export {
  condAttr1,
  condAttr2,
  unitArr
}
