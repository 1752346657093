<template>
  <div class="add">
    <el-dialog
      :title="isEdit? '编辑': '添加'"
      top="30vh"
      :visible="isShow"
      :width="width"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :rules="rules"
        :inline="true"
        :model="form"
        hide-required-asterisk
      >
        <el-form-item label="" prop="attrId">
          <el-select v-model="form.attrId" placeholder="请选择决策字段" @change="attrChange" :disabled="isEdit">
            <el-option
              v-for="item in attrOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="cond">
          <el-select v-model="form.cond" placeholder="请选择条件">
          <el-option
            v-for="item in condOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="" prop="value">
          <el-input v-model.trim="form.value" placeholder="请输入目标值">
<!--            <template slot="append">{{unit}}</template>-->
          </el-input>
        </el-form-item>
<!--        <el-form-item label="" prop="result">-->
<!--          <el-input v-model.trim="result" disabled>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { condAttr1, condAttr2, unitArr } from '../cond'
 export default{
   props:{
     isShow: {
       type: Boolean,
       deafult: false
     },
     isEdit: {
       type: Boolean,
       deafult: false
     },
     attrOpts:{
       type:Array,
       deafult: () => []
     },
     editData:{
       type:Object,
       deafult: () => {}
     },
   },
   data(){
     return {
       width: '40%',
       form:{
         attrId: '',
         cond:'',
         value:'',
       },
       // result:'拒绝',
       condOpts:[],
       rules:{
         attrId:[{ required:true, message:'请选择决策字段', trigger:'change'}],
         cond:[{ required:true, message:'请选择条件', trigger:'change'}],
         value:[{ required:true, message:'请输入目标值', trigger:'blur'}]
       },
       // unit:'岁',
     }
   },
   methods:{
     openDialog(){
       if(document.documentElement.clientWidth < 480){
         this.width = '90%'
       };
       this.$nextTick(() => {
         if(Object.keys(this.editData).length){
           this.attrChange(this.editData.attrId);
           for(let k in this.form){
             this.form[k] = this.editData[k]
           }
           this.form["id"]=this.editData["id"];
         };
       })
     },
     attrChange(value){
       // this.getUnit(value);
       let dataType = this.attrOpts.filter(item => item.id == value)[0].dataType;
       if(dataType == 1){
         this.condOpts = condAttr1;
       }else{
         this.condOpts = condAttr2;
       }
     },
     // getUnit(v){
     //   this.unit = unitArr[v].unit;
     // },
     submit(){
       let check = false;
       this.$refs.form.validate(res => check = res);
       if(!check) return;
       this.$emit('submit', this.form)
       this.$refs.form.resetFields();
     },
     cancel(){
       this.$emit('cancle')
       this.$refs.form.resetFields();
     }
   }
 }
</script>

<style lang="scss" scoped>

</style>
