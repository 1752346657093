import API from "@/api";

export default {
  //获取风控字段列表
  getAttributes: params => {
    return API.requestGet({ url: "risk/attributes", params });
  },
  //获取风控规则列表
  getRules: params => {
    return API.requestGet({ url: "risk/rules", params });
  },
  //添加风控规则
  addPolicy: params => {
    return API.requestPost({ url: "risk/addRules", data:params });
  },
  //删除规则
  delPolicy: params => {
    return API.requestPost({ url: "risk/deleteRules", data:params });
  },
  //编辑规则
  editPolicy: params => {
    return API.requestPost({ url: "risk/updateRules", data:params });
  },
  //过滤开关
  switchStatus: params => {
    return API.requestPost({ url: "basic/update", data:params });
  },
  //获取过滤状态
  getSwitchStatus: params => {
    return API.requestPost({ url: "basic/query", data:params });
  },
}
;
