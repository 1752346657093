<template>
    <div>
      <el-table :data="list" style="width: 100%" border>
        <el-table-column label="序号" type="index" align="center" width="80">
        </el-table-column>
        <el-table-column prop="attrId" label="决策字段" align="center">
          <template slot-scope="scope">
            {{scope.row.attrId | getAttr}}
          </template>
        </el-table-column>
        <el-table-column prop="cond" label="条件" align="center">
          <template slot-scope="scope">
            {{scope.row.cond | getCond(scope.row.attrId)}}
          </template>
        </el-table-column>
        <el-table-column prop="value" label="目标值" align="center">
          <template slot-scope="scope">
            {{scope.row.value}}
          </template>
        </el-table-column>
<!--        <el-table-column label="结果" align="center">-->
<!--          <template>-->
<!--            <el-tag type="danger">拒绝</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              @click="$emit('edit', scope.row)"
            >编辑
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="$emit('del', scope.row.id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
  import { condAttr1, condAttr2, unitArr } from '../cond'
  let that;
  export default {
    name: "policy-table",
    props:{
      list:{
        type: Array,
        default: () => []
      },
      attrOpts:{
        type:Array,
        deafult: () => []
      }
    },
    filters:{
      getAttr(id){
        return that.attrOpts.filter(item => item.id == id)[0].name;
      },
      getCond(condId, attrId){
        let dataType = that.attrOpts.filter(item => item.id == attrId)[0].dataType;
        if(dataType == 1){
          return condAttr1.filter(item => item.value == condId)[0].label;
        }else{
          return condAttr2.filter(item => item.value == condId)[0].label;
        }
      },
      // getUnit(val, id){
      //   let u = unitArr[id].unit;
      //   return val + u;
      // }
    },
    beforeCreate(){
     that = this;
    }
  }
</script>

<style lang="scss" scoped>

</style>
