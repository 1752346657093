<template>
  <div class="policySet c-bg-white">
    <div class="head">
      <el-button type="success" @click="addOpen">添加</el-button>
      <div class="head-switch">
        <span class="label">开启自动过滤</span>
        <el-switch
          v-model="isOpen"
          @change="onChange"
        ></el-switch>
      </div>
    </div>
    <div class="tips">
      <el-popover
        placement="left-start"
        offset="100"
        popper-class="popoverBox"
        trigger="hover">
        <div class="tipBox">
          <p class="p-1">1.您可根据自己的需求灵活设置决策字段、条件、目标值</p>
          <p class="p-1">2.用户进行完所有认证后，您设置的规则将自动执行</p>
          <p class="p-1" style="margin-bottom: 10px">3.每条规则对用户数据有三种命中结果：<span class="c-grey">未命中</span>、<span class="c-green">通过</span>、<span class="c-red">拒绝</span></p>
              <p class="p-2 p-2-title">例如:当您的规则为“用户年龄-小于-22”</p>
              <p class="p-2 p-2-content">用户年龄为18时，命中拒绝</p>
              <p class="p-2 p-2-content">用户年龄为22时，命中通过</p>
              <p class="p-2 p-2-content">用户年龄为25时，命中通过</p>
              <p class="p-2 p-2-content">用户年龄未知时，数据未命中</p>
          <p class="p-1" style="margin-bottom: 10px">4.机审最终结果判定方式</p>
            <p class="p-2 p-2-abc"><span>a)</span>当用户被任意一条规则命中拒绝时，最终审核结果为拒绝</p>
            <p class="p-2 p-2-abc"><span>b)</span>当用户被所有规则命中通过时，最终审核结果为通过</p>
            <p class="p-2 p-2-abc"><span>c)</span>其他情况，无最终审核结果</p>
          <p class="p-1" style="margin-bottom: 10px">5.自动过滤开关</p>
            <p class="p-2 p-2-abc"><span>a)</span>自动过滤为开启状态时，机审结果将直接影响审核结果，代替人工审核</p>
            <p class="p-2 p-2-abc"><span>b)</span>自动过滤开关为关闭状态时，机审结果仅在用户列表展示，不参与人工审核</p>
            <p class="p-2 p-2-abc" style="color:red"><span>c)</span>*建议您在修改决策设置时提前关闭自动过滤，以免造成对用户的误判</p>
        </div>
        <span slot="reference" style="float: right">决策设置说明<i class="el-icon-question"></i></span>
      </el-popover>
    </div>
    <policy-table :list="list" :attrOpts="attrList" @del="delPolicy" @edit="editOpen"></policy-table>
    <add-dialog
      :isShow="showDialog"
      :attrOpts="attrList"
      :isEdit="showEdit"
      :editData="editData"
      @cancle="cancleAdd"
      @submit="submitAdd"
    >
    </add-dialog>
  </div>
</template>

<script>
  import service from './api';
  import addDialog from './components/add-dialog'
  import policyTable from './components/policy-table'
  export default {
    components: { addDialog, policyTable },
    data(){
      return{
        list:[],
        showDialog:false,
        showEdit:false,
        attrList:[],
        editData:{},
        isOpen: false,
      }
    },
    methods:{
      getAttributes(){
        service.getAttributes({}).then(res => {
          this.attrList = res;
        })
      },
      getRules(){
        service.getRules().then(res => {
          this.list = res;
        })
      },
      getSwitchStatus(){
        service.getSwitchStatus({keys:'risk_with_status'}).then(res => {
          this.isOpen = res.risk_with_status == '1' ? true : false;
        })
      },
      addOpen(){
        this.showEdit = false;
        this.editData = {};
        this.showDialog = true;
      },
      editOpen(row){
        this.showEdit = true;
        this.editData = row;
        this.showDialog = true;
      },
      //删除
      delPolicy(id){
        this.$alert('确定要删除该条决策吗？', '提示',
          {
            showCancelButton: true
          }
        ).then(() => {
          service.delPolicy({id}).then(res => {
            this.$notify({message:'删除成功！', offset:'400'})
            this.getRules();
          })
        }).catch(() => {})
      },
      submitAdd(form){
        this.showDialog = false;
        if(this.showEdit){
          return service.editPolicy(form).then(res => {
              this.$notify({message:'编辑成功！', offset:'400'})
              this.getRules();
          })
        }else{
          return service.addPolicy(form).then(res => {
              this.$notify({message:'添加成功！', offset:'400'})
              this.getRules();
          })
        }
      },
      cancleAdd(){
        this.showDialog = false;
      },
      onChange(v){
        let status = 2;
        if(v) status = 1;
        return service.switchStatus({"risk_with_status":status}).then(() => {
          this.$notify({message:'操作成功！', offset:'400'})
        })
      }
    },
    created(){
      this.getAttributes();
      this.getRules();
      this.getSwitchStatus();
    }
  }
</script>

<style lang="scss">
.policySet{
  padding:20px;
  min-height:500px;
  .head{
    text-align: left;
    .head-switch{
      float:right;
      .label{
        color:#606266;
        margin-right:10px;
        font-size: 14px;
        position: relative;
        top:1px;
      }
    }
  }
  .tips{
    color: #606266;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
    overflow: hidden;
  }
}
.popoverBox{
  .tipBox{
    padding: 20px 30px;
    color: #101010;
    .p-1{
      margin-bottom: 15px;
    }
    .p-2{
      color: #828282;
      font-size: 12px;
      margin-bottom: 8px;
    }
    .p-2-title{
      margin-left:30px;
    }
    .p-2-content{
      margin-left:60px;
    }
    .p-2-abc{
      color: #101010;
      margin-left:30px;
      span{
        margin-right: 10px;
      }
    }
  }

}
@media screen and (max-width: 480px) {
 .popoverBox{
   top: 20px!important;
   width: 80%;
   height: 600px;
   .tipBox{
     height: 100%;
     overflow-y: auto;
   }
 }
}
</style>
